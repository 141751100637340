import { put, takeLatest, select } from 'redux-saga/effects';
import { PRICE_CHANGED_SUCCESS } from '../../actions/configuratorActions';

import { estimateSqft, estimateCost } from '../../apps/estimator/calculators';
import { quantitySelector, getSelectedChoicesSelector } from '../../selectors';
import { getSelectedDimension } from '../../utils/getSelectedChoices';
import transform from '../../apps/estimator/dimensionTransformer';

import { safeSaga } from '../utils';
import setEstimatedSqft from '../../apps/actions/setEstimatedSqft';
import setEstimatedCost from '../../apps/actions/setEstimatedCost';
import {recalculatePrice} from '../../actions/index';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../../../../actions/hydration/constants'

export function* priceChanged(action) {
  const quantity = yield select(quantitySelector);
  const { asConfigured: newPrice } = action;
  const estimatedCost = estimateCost(newPrice, quantity);
  yield put(setEstimatedCost(estimatedCost));

  const selectedChoices = yield select(getSelectedChoicesSelector);
  const dimensionChoice = getSelectedDimension(selectedChoices)[0];
  const dimension = transform(dimensionChoice?.choiceValue);
  const estimatedSqft = dimension? estimateSqft(dimension, quantity):0;
  yield put(setEstimatedSqft(estimatedSqft));

  yield put(recalculatePrice());
  
}

export function* priceSaga() {
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
  yield takeLatest(PRICE_CHANGED_SUCCESS, safeSaga(priceChanged));
}

export default priceSaga;
