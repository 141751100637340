import { useEffect } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_DATA } from '../../../../actions/hydration/constants';
/**
 * This hook is in charge of making a graphql call for decks and merging our custom delivery dates with that response.
 */
const useConfiguratorDataFromGraph = ({ productDelivery, partnerProductId, storeId, zipCode, boardPrice }) => {
  // make graph call
  const productOptions = {
    skip: storeId === '8119' || !partnerProductId,
    ssr: false,
    variables: {
      itemId: partnerProductId,
      quantity: 1,
      storeId,
      zipCode,
    }
  };
  const { data, loading, error } = useDataModel('clientOnlyProduct', productOptions);

  // monitor graph call response and delivery dates
  useEffect(() => {
    const getDeliveryDates = async () => {
      const { startDate, endDate } = productDelivery ?? {};

      // If we have graph data, it didn't return any errors, and we have delivery dates
      if (data && !error && !loading && startDate && endDate) {
        // update the delivery dates in graph call response with our delivery dates. The structured clone is because the useDataModel hook
        // returns an object with read only properties. This allows us to update any property we want.
        const newData = structuredClone(data);

        const pricing = newData?.product?.pricing;
        if (pricing && boardPrice) {
          pricing.value = boardPrice;
        }

        newData?.product?.fulfillment?.fulfillmentOptions?.forEach(fulfillmentOption => {
          fulfillmentOption.services.forEach(service => {
            if (service?.deliveryDates) {
              service.deliveryDates.startDate = startDate?.split("T")[0];
              service.deliveryDates.endDate = endDate?.split("T")[0];
            }
          });
        });

        // send updated data to buybox
        LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_DATA, { configuratorData: newData });
      }
    };

    getDeliveryDates();
  }, [data, productDelivery]);
};

export default useConfiguratorDataFromGraph;