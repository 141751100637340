import { connect } from 'react-redux';
import PIP from './pipComponent';
import { updatePath } from '../actions/pathActions';
import { selectedProductSkuDataSelector } from '../../selectors/catalog';
import { getSelectedBoardPriceSelector } from '../../selectors/getSelectedBoardPriceSelector';
import { updatePricing } from '@gcc/configurator';
import soAddtoCart from '../../actions/soAddtocart';
import getThdStore from '../../utils/getThdStore';
import { Utils } from '../../common/utils';

function mapStateToProps(state) {
  const selectedProductSkuData = selectedProductSkuDataSelector(state);
  const boardPrice = getSelectedBoardPriceSelector(state);
  const {
    config: {
      settings
    },
    checkout: {
      quantity,
    },
    configurator: {
      productInfo: {
        partnerProductId,
      },
    },
    productDelivery,
  } = state;
  const localizer = typeof window !== 'undefined' ? window?.THD_LOCALIZER_AUTO_INIT?.Localizer : null;

  return {
    storeSku: ((selectedProductSkuData.sku) ? selectedProductSkuData.sku : 'N/A') || 'N/A',
    bazaarVoiceRatings: state.bazaarVoiceRatings,
    config: state && state.config && state.config.settings,
    settings,
    quantity,
    partnerProductId,
    productDelivery,
    storeId: Utils.getStoreIdFromStateOrCookie(state, getThdStore),
    zipCode: localizer?.getLocalizedStore()?.zipcode,
    boardPrice,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadSuccess: () => {
      dispatch(updatePath());
      const customATC = (cartOptionsFromBuybox) => {
        return dispatch(soAddtoCart(cartOptionsFromBuybox));
      }
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrder", { isSpecialOrder: true });
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.isSpecialOrderATC", { customATC });
    },
    updatePricing:() => {
      dispatch(updatePricing());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PIP);
