import {
  call, takeLatest, put, select
} from 'redux-saga/effects';
import { CHOICE_CHANGED_SUCCESS } from '@gcc/configurator/dist/actions/actionTypes';
import { HARDWARE_SUBAPP_PRODUCT_SELECTION } from '../../_SubApps/HardwareAssembly/actions';
import { updateProductDelivery, GET_MEDIA_GALLERY_IMAGES_SUCCESS } from '../../actions';
import { getEstimatedDeliveryTime } from '../../services';
import { safeSaga } from '../utils';
import { UPDATE_QUANTITY } from '../../apps/actions/actionTypes';
import productModel from '../../utils/productModel';
import {
  inventoryModeSelector,
  selectedProductSkuDataSelector,
} from '../../selectors/catalog';
import { getSelectedStore } from '../../utils/getSelectedStoreInfo';
import quoteCenterOffering from '../../utils/Carting/kitCartOrchestration/quoteCenterOffering';
import inventoryModes from '../../utils/inventoryModes';
import configurationSettings from '../../selectors/configuratorSettings'
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../../../../actions/hydration/constants';

function* getVendorInformation(baseUrl, storeId, sku) {
  const deckingInfo = {
    deckingCalculatorUrl:`https://custom.homedepot.com/decking-calculator?sku=${sku}`,
  };
  LIFE_CYCLE_EVENT_BUS.trigger("configurator.configurator_decking_event", deckingInfo);
  const { data } = yield call(quoteCenterOffering, baseUrl, storeId, sku);
  const theOffering = data.data ? data.data[0] : {};
  return theOffering;
}

function* getEstimatedDeliveryDate() {
  const inventoryMode = yield select(inventoryModeSelector);
  
  const configuratorSettings = yield select(configurationSettings);
  // console.log('configurator settings selector data=>',configuratorSettings);
  if (inventoryMode !== inventoryModes.SPECIALORDER) return;

  const selectedProductSku = yield select(selectedProductSkuDataSelector);
  const { storeId } = getSelectedStore();
  if (!(selectedProductSku.sku)) {
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
    LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
    return
  }

  const offering = yield getVendorInformation(
    configuratorSettings.decksApiUrl,
    storeId,
    selectedProductSku.sku,
  );
  const payload = {
    siteId: configuratorSettings.siteId,
    storeId,
    productId: configuratorSettings.productId,
    mVendorNumber: offering.mvendorNumber,
    leadtime: configuratorSettings.productionTime ? configuratorSettings.productionTime : configuratorSettings.defaultLeadTime,
    sku: selectedProductSku.sku,
  };

  const { status, data } = yield call(getEstimatedDeliveryTime, payload, configuratorSettings.decksApiUrl);
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: false });
  LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);


  if (status === 200) {
    if (data.isSuccessful) {
      LIFE_CYCLE_EVENT_BUS.trigger("configurator.set_lead_time", data.productionLeadTimeInDays);
      return yield put(updateProductDelivery(data.startDate, data.endDate));
    }
  }
  
  yield put(updateProductDelivery(
    productModel.estimatedDeliveryStartDate,
    productModel.estimatedDeliveryEndDate,
  ));
}

export function* productDeliverySaga() {
  yield takeLatest(
    ["GCC_CONFIGURATOR/EXECUTE_RULES_SUCCESS", CHOICE_CHANGED_SUCCESS, HARDWARE_SUBAPP_PRODUCT_SELECTION, GET_MEDIA_GALLERY_IMAGES_SUCCESS, UPDATE_QUANTITY],
    safeSaga(getEstimatedDeliveryDate),
  );
}

export default productDeliverySaga;
